import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CommunityHome, Waiting } from '../../components';
import SearchAmigo from '../SearchAmigo';
import ManageBuddies from '../../components/PeopleHomeV2/ManageBuddies/index'
import { getCompanies as getAllCompanies, getCompany, getUserId } from '../../redux/actions';
import { setCompany } from '../../redux/actions/challengeActions';
import { withTranslation } from 'react-i18next';

class EmployeeDirectory extends Component {

  componentDidMount() {
    const { fetchAllCompanies, role, userCompany, setCompany, fetchCompany, getUserId } = this.props;
    getUserId()
    if(role === "ADMIN") {
      fetchAllCompanies();
    } else {
      setCompany(userCompany.id, userCompany.name);
      fetchCompany();
    }
  }

  peopleHomeV2 = () =>{
    const { companies, companyInfo, setCompany, history } = this.props;
    return(<CommunityHome companies={companies} history={history} companyInfo={companyInfo} setCompany={setCompany} searchAmigo={true} showView={true}/>)
  }

  manageBuddies = () =>(
    <ManageBuddies userId={this.props.userId}/>
  )
  
  render() {
    const { companies, companyInfo, role } = this.props;
    if(!companyInfo || (role === 'ADMIN' && !companies)) {
      return <Waiting />
    }
    
    return (
      <Switch>
        <Route
          path="/community/invite"
          component={SearchAmigo}
        />
        <Route
          path="/community/manage-buddies"
          render={this.manageBuddies}
        />
        <Route
          path="/community"
          render={this.peopleHomeV2}
        />
      </Switch>
    )
  }
}

EmployeeDirectory.propTypes = {
  isAdmin: PropTypes.string,
  history: PropTypes.object.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array,
  companyInfo: PropTypes.object,
  setCompany: PropTypes.func.isRequired,
  role: PropTypes.string,
  userCompany: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  getUserId: PropTypes.func,
  userId: PropTypes.number,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  companies: state.companyDashboard.companies,
  companyInfo: state.companyDashboard.companyInfo,
  userId: state.profileData.userId
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  setCompany: (id, name) => dispatch(setCompany(id, name)),
  fetchCompany: () => dispatch(getCompany()),
  getUserId: () => dispatch(getUserId()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)((withTranslation()) (EmployeeDirectory));