import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavigationBarV2, LandingFooterPanelV2, ContactV2, Layout } from '../../components';

import { checkPermission } from '../../utils/methods';

class ContactUs extends Component {
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;   
    const showThankYou = history.location.pathname === '/thankyou' ? true : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <ContactV2 history={history} showThankYou={showThankYou}/>
        <LandingFooterPanelV2 isUser={authToken} />
      </Layout>
    )
  }
}

ContactUs.propTypes = {
  history: PropTypes.object.isRequired
};

export default ContactUs;