import styled, { css } from 'styled-components';

import { StyledTab } from '../Settings/styles';

const Layout = styled.div`
  width: 100%;
  padding: 25px;
  margin: auto;
  
  @media (max-width: 500px) {
    padding: 30px 0;
  }

  ${({ isArticlePage }) => isArticlePage && css`
    ul.nav.nav-tabs {
      display: none !important;
    }
  `}
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 32px;
  > span {
    font-size: 24px;
    letter-spacing: 1px;
    color: rgb(51,51,51);
    @media (max-width: 500px) {
      margin-bottom: 40px;
      float: left;
      width: 100%;
    }
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
  ${({ isArticlePage }) => isArticlePage && css`
    display: none;

    @media (max-width: 500px) {
      display: none;
    }
  `}
`;

const CustomTabs = styled(StyledTab)`
    margin-top: 25px;
    width: 67.1%;
    padding: 20px 20px 1px;
    margin-top: 25px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: space-around;
    padding: 20px 20px 1px 20px;
    margin-top: 25px;
    background-color: white;
    border-radius: 6px;
    position: ${({position}) => position === 1 ? '' : 'relative'};
    bottom: 150px;
    .nav-item > .active {
      border-bottom: 3px solid rgb(13, 66, 112);
      width: 100%;
    }
    li {
      border: none;
      width: 25%;
      display: flex;
      justify-content: space-around;
    }
    li > .nav-link {
      border: 0px;
      background-color: none !important;
      margin: auto;
      padding: 5px 5px 12px;
      text-transform: capitalize;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: rgb(156, 156, 156);
    }
    li > .nav-link > .active {
      background-color: none !important;
      margin: auto;
      padding: 5px 5px 12px;
      text-transform: capitalize;
      font-weight: bolder !important;
      font-family: Rubik;
      font-style: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: rgb(156, 156, 156);
    }
    li > button {
      font-weight: bold !important;
    }
    ${'' /* > ul {
      width: 67.1%;
      padding: 20px 20px 1px 20px;
      margin-top: 25px;
      background-color: white;
      border-radius: 6px;
      border: 1px solid red;
      display: flex;
      -webkit-box-pack: center;
      justify-content: space-around;
    } */}
`;

const CardWrapper = styled.div`
display: flex;
max-width: 1246px;
margin: auto;
>div:first-child {
  width: 100%;

}
>div:last-child {
  width: 100%;
  
}
`;

const DropDownContainer = styled.div`
  min-width: 177px;
  width: auto;

  @media (max-width: 500px) {
    margin-top: 15px;
  }
`;

const Recipe = styled.div`
 
`;

const Heading = styled.div`
 width: 100%;
 margin-bottom: 15px;
 text-transform: capitalize;
    font-family: Rubik-Medium;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;
`;
export { Layout, HeaderContainer, CustomTabs, DropDownContainer, CardWrapper, Recipe, Heading };