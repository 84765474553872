/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { WellnessAdminMain, AddWellnessJourney, AddFitnessVideo, CreateRecipe, AddCategory } from "../../components";
import SkeletonLoder from "../../components/common/skeletonLoder";
import { withTranslation } from 'react-i18next';
import { getSelectedAdventureCategories, getThemes, getAdventureCategories, getSelectedVideosCategory} from '../../redux/actions';
import { connect } from 'react-redux';
import { Container } from "../../components/ChallengeDashboardV2/CompletedChallenge/styles";

class WellnessAdmin extends Component {

  componentDidMount() {
    const {
      fetchSelectedAdventureCategories,
      fetchThemes,
      fetchAdventureCategories,
      fetchSelectedVideosCategory
    } = this.props;
    fetchSelectedAdventureCategories();
    fetchThemes();
    fetchAdventureCategories();
    fetchSelectedVideosCategory();
  }

  CompanyWellness = () => (
    <WellnessAdminMain
      companyInfo={this.props.companyInfo}
      history={this.props.history}
      yearList={this.props.yearList}
      selectedYearInfo={this.props.selectedYearInfo}
      role={this.props.role}
    />
  );

  CreateWellnessArticle = () => (
    <Container>
      <div className="mainContainer">
        <AddWellnessJourney
          adventureCategories={this.props.selectedAdventureCategories}
          themes={this.props.themes}
          themesHeaderAll={this.props.themesHeaderAll}
          isAdminRoute={1}
          history={this.props.history}
        />
      </div>
    </Container>
  );

  CreateArticleCategory = () => (
    <Container>
      <div className="mainContainer">
        <AddCategory
          categoryKey={"ARTICLE"}
          history={this.props.history}
          isAdminRoute={1}
        />
      </div>
    </Container>
  );

  CreateFitnessVideo = () => (
    <Container>
      <div className="mainContainer">
        <AddFitnessVideo
          videoLibraries={this.props.selectedvideoLibraries}
          adventureCategories={this.props.adventureCategories}
          themes={this.props.themes}
          themesHeaderAll={this.props.themesHeaderAll}
          history={this.props.history}
          isAdminRoute={1}
        />
      </div>
    </Container>
  );

  CreateFitnessCategory = () => (
    <Container>
      <div className="mainContainer">
        <AddCategory
          categoryKey={"FITNESS"}
          history={this.props.history}
          isAdminRoute={1}
        />
      </div>
    </Container>
  );

  CreateRecipeVideo = () => (
    <Container>
      <div className="mainContainer">
        <CreateRecipe
          history={this.props.history}
          themes={this.props.themes}
          themesHeaderAll={this.props.themesHeaderAll}
          isAdminRoute={1}
        />
      </div>
    </Container>
  );

  CreateRecipeCategory = () => (
    <Container>
      <div className="mainContainer">
        <AddCategory
          categoryKey={"RECIPE"}
          history={this.props.history}
          isAdminRoute={1}
        />
      </div>
    </Container>
  );

  render() {
    const {
      companyInfo
    } = this.props;
    if (!companyInfo) {
      return <div style={{width:'1246px', margin:'auto', padding:'25px 0px'}}>
        <SkeletonLoder width={"100%"} height={"60px"}/>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <SkeletonLoder width={"300px"} height={"25px"} style={{margin:"25px 0px"}}/>
          <SkeletonLoder width={"100px"} height={"25px"} style={{margin:"25px 0px"}}/>
        </div>
        <SkeletonLoder width={"100%"} height={"83px"} style={{marginBottom:"25px"}}/>
        <div style={{width:'100%', display:'flex', gap:'25px', flexWrap:'wrap'}}>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
        </div>
      </div>;
    }

    return (
      <Switch>
        <Route path="/company/wellness" render={this.CompanyWellness} exact={true} />
        <Route path="/company/wellness/create-adventure" render={this.CreateWellnessArticle} exact={true} />
        <Route path="/company/wellness/create-video" render={this.CreateFitnessVideo} exact={true} />
        <Route path="/company/wellness/create-recipe" render={this.CreateRecipeVideo} exact={true} />
        <Route path="/company/wellness/create-category" render={this.CreateArticleCategory} exact={true} />
        <Route path="/company/wellness/create-video-category" render={this.CreateFitnessCategory} exact={true} />
        <Route path="/company/wellness/create-recipe-category" render={this.CreateRecipeCategory} exact={true} />
      </Switch>
    );
  }
}

WellnessAdmin.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  userPermissions: PropTypes.array,
  companyInfo: PropTypes.array,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  fetchSelectedAdventureCategories: PropTypes.func,
  selectedAdventureCategories: PropTypes.array,
  fetchThemes: PropTypes.func,
  themes: PropTypes.array,
  themesHeaderAll:PropTypes.array,
  adventureCategories: PropTypes.array,
  fetchAdventureCategories: PropTypes.func,
  selectedvideoLibraries: PropTypes.array,
  fetchSelectedVideosCategory: PropTypes.func
};

const mapStateToProps = (state) => ({
  themes: state.education.themes,
  selectedAdventureCategories: state.education.selectedAdventureCategories,
  themesHeaderAll:state.profileData.themesHeaderAll,
  adventureCategories: state.education.adventureCategories,
  selectedvideoLibraries: state.education.selectedvideoLibraries
});

const mapDispatchToProps = (dispatch) => ({
  fetchThemes: () => dispatch(getThemes()),
  fetchSelectedAdventureCategories: () => dispatch(getSelectedAdventureCategories()),
  fetchAdventureCategories: () => dispatch(getAdventureCategories()),
  fetchSelectedVideosCategory: () => dispatch(getSelectedVideosCategory())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WellnessAdmin));
